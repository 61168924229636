var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-center" }, [
    _c(
      "div",
      { staticClass: "col-12 col-md-5 q-pa-md" },
      [
        _c("q-select", {
          ref: "tipo_simplo",
          attrs: {
            outlined: "",
            options: _vm.options_tipo_simplo,
            label: "Tipo di simplo",
          },
          model: {
            value: _vm.tipo_simplo,
            callback: function ($$v) {
              _vm.tipo_simplo = $$v
            },
            expression: "tipo_simplo",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12 col-md-5 q-pa-md" },
      [
        _c("q-input", {
          ref: "nominativo_cliente",
          attrs: { outlined: "", label: "Nominativo Cliente" },
          model: {
            value: _vm.nominativo_cliente,
            callback: function ($$v) {
              _vm.nominativo_cliente = $$v
            },
            expression: "nominativo_cliente",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12 col-md-12" },
      [
        _c("q-table", {
          attrs: {
            title: "Elenco dei Simpli da caricare",
            data: _vm.elenco_filtrato,
            columns: _vm.colonne,
            dense: _vm.$q.screen.lt.md,
            "row-key": "prodotto_id",
            "visible-columns": _vm.visibleColumns,
            "wrap-cells": "",
          },
          scopedSlots: _vm._u([
            {
              key: "top-right",
              fn: function () {
                return [
                  _c("q-btn", {
                    attrs: {
                      color: "primary",
                      "icon-right": "archive",
                      label: "ESPORTA IN CSV",
                      "no-caps": "",
                    },
                    on: { click: _vm.esportaTabella },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _vm._l(props.cols, function (col) {
                        return _c(
                          "q-th",
                          { key: col.name, attrs: { props: props } },
                          [_vm._v(" " + _vm._s(col.label) + " ")]
                        )
                      }),
                      _c("q-th", [_vm._v(" Opzioni ")]),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "body",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _vm._l(props.cols, function (row) {
                        return _c(
                          "q-td",
                          { key: row.name, attrs: { props: props } },
                          [_vm._v(" " + _vm._s(row.value) + " ")]
                        )
                      }),
                      _c(
                        "q-td",
                        [
                          props.cols[2].value === "NON_CARICATO"
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "green",
                                  glossy: "",
                                  label: "CARICA SIMPLO",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onUploadSimpli(props.row)
                                  },
                                },
                              })
                            : _vm._e(),
                          props.cols[2].value === "CARICATO_SCARTATO"
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "deep-orange",
                                  glossy: "",
                                  label: "CARICA SIMPLO",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onUploadSimpli(props.row)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        }),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }